<template>
  <v-card width="100%">
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="primary" @click="setToday">
              Hoy
            </v-btn>
            <v-btn fab text small color="primary" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="primary" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title.toUpperCase() }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn @click.stop="createEvent" v-if="canAddEvent" color="primary" class="mr-2">
              <v-icon left >mdi-plus</v-icon>
              Nuevo evento
            </v-btn>

            <v-btn @click.stop="getEventos" color="primary" class="mr-2">
              <v-icon left >mdi-reload</v-icon>
              Recargar
            </v-btn>

            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet class="pa-2">
          <v-calendar
            color="primary"
            ref="calendar"
            v-model="focus"
            :events="events"
            @click:event="showEvent"
            @contextmenu:event="showEvent"
            :type="type"
            :key="keyHelper"
            :event-height="40"
            :event-more="false"
            :weekdays="weekdays"
            @change="getEventos"
            :event-color="(e) => null"
          >
            <template v-slot:event="{ event }">
              <EventoCalendario class="v-event--thick" :class="{cancelado : event.cancelado}" :event="event" :idEstudio="idEstudio"  />
            </template>

            <template v-slot:day-label="{ date, past, day, present }">
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  small
                  :elevation="0"
                  :text="!present"
                  :color="present ? 'primary' : ''"
                  :class="{
                    'text-end': true,
                    'grey--text': past,
                    'mr-1': true,
                    'mb-1': true,
                  }"
                  @dblclick.stop="createEvent(date)"
                >
                  {{ day }}
                </v-btn>
              </div>
            </template>
          </v-calendar>

          <!-- <div class="d-flex align-center" style="gap: 10px" >
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
          </div> -->

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            transition="slide-y-transition"
          >
            <DetallesEvento :key="selectedEvent?.idEvento" :event="selectedEvent" @reload="getEventos" @close="selectedEvent = null" >
            </DetallesEvento>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog max-width="600" v-model="creatingEvent" >
      <NuevoEvento :estudio="idEstudio" :starting-date="startingDate" :key="creatingEvent" @close="creatingEvent = false" @reload="getEventos" />
    </v-dialog>

  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    EventoCalendario: () => import("../calendario/EventoCalendario.vue"),
    DetallesEvento: () => import("../calendario/DetallesEvento.vue"),
    NuevoEvento: () => import("../calendario/NuevoEvento.vue"),
  },
  props: {
    date: Date,
    idEstudio: Number | String,
  },
  data() {
    return {
      focus: Date.now(),

      creatingEvent: false,
      startingDate: null,

      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,

      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        "4day": "4 Días",
      },
      type: "month",
      keyHelper: 0,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      events: [],
    };
  },
  methods: {
    parseDate,
    createEvent(date) {
      if (!this.canAddEvent) return;
      this.startingDate = `${date} 00:00`
      this.creatingEvent = !this.creatingEvent
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async getEventos(date) {
      let start = null
      let end = null
      if (date) {
        start = date.start
        end = date.end
      }
      if (start == null) 
        start = this.$refs.calendar.lastStart
      if (end == null) 
        end = this.$refs.calendar.lastEnd

      this.events = []
      const { data } = await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/calendario_eventos`,
        data: {
          idEstudio: this.idEstudio,
          start : start.date + ' 00:00:00',
          end : end.date + ' 23:59:59',
        },
      });
      this.events = data
    },
  },
  computed: {
    canAddEvent() {
      return this.idEstudio && this.$root.acceso('EDITAR_ESTUDIOS')
    }
  }
};
</script>

<style>
.v-calendar-weekly__day {
  min-height: 80px;
  padding: 3px !important;
}
.v-calendar-weekly__day.v-present {
  border: 1px solid var(--v-primary-base) !important;
}
.v-event {
  height: unset !important;
}
.v-event .cancelado {
  opacity: 0.3;
}
.v-event .v-event--thick {
  min-height: 30px !important;
}
</style>
